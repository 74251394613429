import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header/header";
import SwiperCore, { Autoplay, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/effect-coverflow/effect-coverflow.scss";
require("swiper/swiper-bundle.css");
import Img from "gatsby-image";
import parse from "html-react-parser";

SwiperCore.use([Autoplay, EffectCoverflow]);

const query = graphql`
	query {
		strapiPageMaterielPedagogique {
			title
			slug
			body
			seo {
				metaDescription
				metaTitle
			}
		}
		slidePictures: allFile(
			filter: { sourceInstanceName: { eq: "material" } }
			sort: { fields: name }
		) {
			edges {
				node {
					childImageSharp {
						fixed(width: 260) {
							...GatsbyImageSharpFixed_withWebp_noBase64
						}
					}
				}
			}
		}
	}
`;

const MaterielPedagogique = () => {
	const data = useStaticQuery(query);
	const page = data.strapiPageMaterielPedagogique;
	console.log(page.materials);

	return (
		<Layout seo={page.seo}>
			<div className={page.slug}>
				<Header title={page.title}></Header>
				<div className={`container`}>
					<div className="sub-container">{parse(page.body)}</div>
					<Swiper
						effect={`coverflow`}
						grabCursor={true}
						centeredSlides={true}
						slidesPerView={`auto`}
						coverflowEffect={{
							rotate: 50,
							stretch: 0,
							depth: 100,
							modifier: 1,
							slideShadows: true,
						}}
						speed={2000}
						autoplay={{ delay: 2000 }}
						className={`mt-5`}
					>
						{data.slidePictures.edges.map((image, index) => {
							let material = {
								url: "",
								title: "",
							};
							switch (index) {
								case 0:
									material.url = `https://boutique.interactif.be/index.php?id_product=55&controller=product`;
									material.title = `Base 10 set complet avec livret | Materiel Pedagogique PNL`;
									break;
								case 1:
									material.url = `https://boutique.interactif.be/index.php?id_product=42&controller=product`;
									material.title = `Cubes pour la représentation spaciale | Materiel Pedagogique PNL`;
									break;
								case 2:
									material.url = `https://boutique.interactif.be/index.php?id_product=49&controller=product`;
									material.title = `Hérodote - Jeu de société sur les leçons à tirer de l'histoire | Materiel Pedagogique PNL`;
									break;
								case 3:
									material.url = `https://boutique.interactif.be/index.php?id_product=47&controller=product`;
									material.title = `Jeu des tables de multiplication | Materiel Pedagogique PNL`;
									break;
								case 4:
									material.url = `https://boutique.interactif.be/index.php?id_product=37&controller=product`;
									material.title = `Mémoriser les cartes de géographie | Materiel Pedagogique PNL`;
									break;
								case 5:
									material.url = `https://boutique.interactif.be/index.php?id_product=48&controller=product`;
									material.title = `Plus grand, plus petit ou égale - Monstre Magnet | Materiel Pedagogique PNL`;
									break;
								case 6:
									material.url = `https://boutique.interactif.be/index.php?id_product=23&controller=product`;
									material.title = `Préparation base 10 - Addition et soustraction | Materiel Pedagogique PNL`;
									break;
							}

							return (
								<SwiperSlide key={image.node.childImageSharp.fixed.src}>
									<div className={`slide`}>
										<Img
											fixed={image.node.childImageSharp.fixed}
											alt={material.title}
											title={material.title}
										/>
									</div>
									<div className={`button-action`}>
										<a href={material.url} target="_blank" rel="noopener">
											<button className={`btn btn--green`}>
												En savoir plus{" "}
											</button>
										</a>
									</div>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</div>
			</div>
		</Layout>
	);
};

export default MaterielPedagogique;
